
.toast {
    flex-basis: 350px;
    /* max-height:50px; */
    max-width: 400px;
    font-size: .875rem;
    /* background-color:none !important; */
    background-clip: padding-box;
    border-bottom: none !important;
    box-shadow: 0 0.25rem 0.75rem rgb(0 0 0 / 10%);
    opacity: 0;
    border-radius: .25rem;
}
.toast-header {
    display: flex;
    align-items: center;
    padding: .25rem .75rem;
    color: #6c757d;
    /* background-color: #f8f8f8; */
    background-clip: padding-box;
    border-bottom: none !important;
    border-top-left-radius: calc(.25rem - 1px);
    border-top-right-radius: calc(.25rem - 1px);
}
.uploadWrapContainer .drop-it-hot {
    border: 1px dashed #D9D9D9;
    background-color: #F2F2F2;
    border-radius: 15px;
    /* width: 700px; */
    /* height: 500px; */
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 8px 15px 0 rgba(black, .1);
    overflow: hidden;
    padding: 20px;
}

.uploadWrapContainer .drop-it-hot:after {
    content: '';
    position: absolute;
    width: calc(100% - 20px);
    height: calc(100% - 20px);
    /* border: 2px dashed #E6E7F0; */
    border-radius: 5px;
    z-index: 0;
}

.uploadWrapContainer .choose-files {
    align-self: flex-end;
    z-index: 10;
}

.uploadWrapContainer .button-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    overflow: hidden;
    cursor: pointer;
}

.uploadWrapContainer .button-wrapper.btn:hover {
    background-color: #4E5056;
}

.uploadWrapContainer .label {
    font-weight: 700;
    width: 157px;
    text-align: center;
    margin: 20px 0;
    color: #4E5056;
    font-size: 18px;
}
.uploadWrapContainer .selectedFileLabel {
    margin: 20px 0;
    color: #4E5056;
    font-size: 14px;
}

.uploadWrapContainer .button-wrapper input[type=file] {
    font-size: 100px;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    width: 100%;
    height: 40px;
}


.uploadWrapContainer .btn {
    border: none;
    padding: 8px 20px;
    /* border-radius: 50px; */
    background-color: #febf42;
    color: #000000;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 200ms ease-in;
}

.uploadWrapContainer #gallery {
    position: absolute;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 100;
}
.dwnldBtn {
    text-decoration: none;
    color: black;
    text-transform: capitalize;
    font-weight: bold;
    font-family: "Segoe UI";
    padding: 10px 20px;
    font-size: 14px;
}
.dwnldBtn:hover{
    color: #ffffff !important;
    text-decoration: none;
}
.validationWrapper {
    position: absolute;
    background-image: url('../../Assets/Images/transparentBg.png');
    width: 100%;
    height: calc(100% + 65px);
    z-index: 10;
    top: 0;
    left: 0;
    text-align: center;
    padding: 20px;
}
.hideValidation {
    display: block;
}
.noRecord {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  color: black;
}